<template>
  <v-row>
    <v-col cols="12" :md="isRange ? '6' : '12'">
      <v-text-field
        v-model="rangeStart"
        color="indigo darken-4"
        :label="`${label} ${isRange ? '( From )' : ''}`"
        @focus="triggerRangeStart"
      ></v-text-field>
      <Datetime
        class="date-time-range"
        ref="rangeStart"
        v-bind="{ ...$props, ...$attrs }"
        v-model="rangeStart"
        @input="updateTrigger"
      />
    </v-col>
    <v-col v-if="isRange" cols="12" :md="isRange ? '6' : '12'">
      <v-text-field
        v-model="rangeEnd"
        color="indigo darken-4"
        :label="`${label} ${isRange ? '( To )' : ''}`"
        @focus="triggerRangeEnd"
      ></v-text-field>
      <Datetime
        class="date-time-range"
        ref="rangeEnd"
        v-bind="{ ...$props, ...$attrs }"
        v-model="rangeEnd"
        @input="updateTrigger"
      />
    </v-col>
  </v-row>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
export default {
  model: {
    event: "change"
  },
  props: {
    label: {
      type: String,
      default: null
    },
    range: {
      type: Object,
      default: null
    },
    selectType: {
      type: String,
      default: "dateRange"
    }
  },
  data() {
    return {
      rangeStart: "",
      rangeEnd: ""
    };
  },
  computed: {
    isRange() {
      return ["dateRange", "dataTimeRange"].includes(this.selectType);
    },
    isdate() {
      return this.selectType == "date";
    }
  },
  components: {
    Datetime
  },
  methods: {
    triggerRangeStart() {
      this.$refs.rangeStart.$el.querySelector("input").click();
    },

    triggerRangeEnd() {
      this.$refs.rangeEnd.$el.querySelector("input").click();
    },

    rangeUpdate(range) {
      this.rangeStart = range?.rangeStart || "";
      this.rangeEnd = range?.rangeEnd || "";
    },

    updateTrigger() {
      if (this.isRange) {
        if(this.rangeStart){
          this.rangeStart = this.rangeStart.slice(0, 10)
          if(this.rangeEnd){
            this.rangeEnd = this.rangeEnd.slice(0, 10)
          }
          if (this.rangeStart && this.rangeEnd) {
            this.$emit("change", this.rangeStart + " ~ " + this.rangeEnd)
          }
        }
        if(this.rangeEnd){
          this.rangeEnd = this.rangeEnd.slice(0, 10)
          if(this.rangeStart){
            this.rangeStart = this.rangeStart.slice(0, 10)
          }
          if (this.rangeStart && this.rangeEnd) {
            this.$emit("change", this.rangeStart + " ~ " + this.rangeEnd)
          }
        }
      }  
    }
  },
  watch: {
    range: {
      immediate: true,
      deep: true,
      handler: "rangeUpdate"
    }
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.field-group input {
  width: 100%;
}

.date-time-range {
  position: absolute;
  left: -1500px;
}
</style>
